// Import libraries
import { css } from '@emotion/core';

// Import styles
import { colors } from '../../../../styles/presets';

// Prepare stylesheet
const styles = {
    timeline: css({
        marginTop: '30px',
        '.react_time_range__time_range_container': {
            padding: '45px 0px',
            width: '100%',
        },
        '.react_time_range__track': {
            backgroundColor: `${colors.primary}!important`,
            borderLeft: `1px solid ${colors.primary}!important`,
            borderRight: `1px solid ${colors.primary}!important`,
        },
        '.react_time_range__rail__inner': {
            backgroundColor: '#e0f0dc',
        },
        '.react_time_range__track__disabled': {
            // backgroundColor: colors.dustRed,
            // borderLeft: `1px solid ${colors.dustRed}`,
            // borderRight: `1px solid ${colors.dustRed}`,
        },
    }),
    preview: css({
        width: '180px',
        height: '180px',
        border: '0',
        backgroundColor: '#F8F7F7',
    }),
    timePicker: css({
        width: '80%',
    }),
    actions: css({
        marginTop: '35px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }),
};

export default styles;
