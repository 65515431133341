/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import API from '@aws-amplify/api';
import moment from 'moment-timezone';
import { useContext, useState, useEffect, useMemo } from 'react';

// Import Ant Design components
import {
    PageHeader,
    Card,
    DatePicker,
    Button,
    Form,
    Select,
    Radio,
    Space,
    Badge,
    message,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

// Import stores
import { UserContext } from '../../store/UserContext';
import { OrgsContext } from '../../store/OrgsContext';
import history from '../../store/history';

// Import components
import Results from './components/Results';
import Downloads from './components/Downloads';

// Import utilities
import { useAmplifyOrgGetAPI } from '../../utilities/hooks';
import { connectRequestHub } from '../../utilities/requestHub';

// Import stylesheet
import styles from './styles';

// Import additional Ant Design components
const { Option } = Select;

const TimeBasedDownload = () => {
    // Initialisation
    const [form] = Form.useForm();
    const [searchVehicleId, setSearchVehicleId] = useState(undefined);
    const [sdAvailable, setSdAvailable] = useState(true);
    const [searching, setSearching] = useState(false);
    const [results, setResults] = useState(undefined);

    const [reqHubSocket, setReqHubSocket] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const [downloadRequests, setDownloadRequests] = useState([]);

    // Retrieve user timezone
    const userContext = useContext(UserContext);
    const { timezone } = userContext;

    // Retrieve currently selected organisation
    const orgContext = useContext(OrgsContext);
    const { selectedOrg } = orgContext;

    // Fetch vehicle list
    const { data: orgVehicles } = useAmplifyOrgGetAPI(selectedOrg, '/vehicles', []);

    useEffect(() => {
        // Connect to request hub if not connected
        if (!reqHubSocket) {
            connectRequestHub(selectedOrg).then((socketeIO) => {
                socketeIO.on('connect', () => setIsReady(true));
                socketeIO.on('disconnect', () => setIsReady(false));
                setReqHubSocket(socketeIO);
            });
        }

        return () => {
            // Disconnect from request hub if connected
            if (reqHubSocket) {
                reqHubSocket.disconnect();
                setReqHubSocket(null);
            }
        };
    }, [reqHubSocket]);

    const handleHistorySearch = async ({ vehicleId, searchDate }) => {
        // Check if vehicle rego and search date are provided
        if (!vehicleId || !searchDate) {
            message.error('Please enter a vehicle rego and search date.');
            return;
        }

        try {
            setSearching(true);

            const { sdAvailable, data } = await API.get('history', '/time', {
                queryStringParameters: {
                    vehicleId,
                    date: searchDate.unix(),
                    timezone,
                    selectedOrg,
                },
            });
            setSdAvailable(sdAvailable);
            setResults(data);
        } catch (error) {
            message.error('Unable to search for recordings, please try again later.');
            setResults(undefined);
        } finally {
            setSearching(false);
            setSearchVehicleId(vehicleId);
            setDownloadRequests([]);
        }
    };

    const selectedVehicle = useMemo(() => {
        const vehicle = orgVehicles.find((v) => v.id === searchVehicleId);
        return {
            vehicleId: vehicle?.id,
            rego: vehicle?.rego,
            deviceId: vehicle?.deviceId,
            online: vehicle?.online === 'online',
        };
    }, [orgVehicles, searchVehicleId]);

    return (
        <div>
            <PageHeader
                title="Time-based History Search"
                breadcrumb={null}
                ghost={false}
                extra={[
                    <Radio.Group
                        buttonStyle="solid"
                        value="/history/time"
                        onChange={(e) => {
                            console.log(e.target.value);
                            history.push(e.target.value);
                        }}
                    >
                        <Radio.Button value="/history/map">Map View</Radio.Button>
                        <Radio.Button value="/history/time">Time View</Radio.Button>
                    </Radio.Group>,
                ]}
            />

            <div css={styles.contentWrapper}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Card style={{ width: '70%' }} loading={!isReady}>
                        <Form
                            layout="inline"
                            form={form}
                            onFinish={handleHistorySearch}
                            initialValues={{ vehicleId: undefined, searchDate: moment() }}
                        >
                            <Form.Item key="searchDate" name="searchDate" label="Search Date">
                                <DatePicker
                                    disabledDate={(current) => current > moment()}
                                    style={{ width: '200px' }}
                                />
                            </Form.Item>
                            <Form.Item key="vehicleId" name="vehicleId" label="Search Rego">
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="Enter vehicle rego"
                                    style={{ width: '200px' }}
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {orgVehicles
                                        .sort((a, _) => (a.online === 'online' ? -1 : 1))
                                        .map((vehicle) => (
                                            <Option
                                                value={vehicle.id}
                                                label={vehicle.rego}
                                                disabled={vehicle.online !== 'online'}
                                            >
                                                <Space>
                                                    <Badge
                                                        status={
                                                            vehicle.online === 'online'
                                                                ? 'success'
                                                                : 'error'
                                                        }
                                                    />
                                                    {vehicle.rego}
                                                </Space>
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    icon={<SearchOutlined />}
                                    loading={searching}
                                >
                                    Search
                                </Button>
                            </Form.Item>
                        </Form>

                        <div css={styles.resultsPanel}>
                            <Results
                                vehicle={selectedVehicle}
                                sdAvailable={sdAvailable}
                                data={results}
                                reqHubSocket={reqHubSocket}
                                onRequestCreated={(req) =>
                                    setDownloadRequests((current) => [req, ...current])
                                }
                            />
                        </div>
                    </Card>

                    <Downloads
                        vehicle={selectedVehicle}
                        reqHubSocket={reqHubSocket}
                        isReady={isReady}
                        downloadRequests={downloadRequests}
                    />
                </div>
            </div>
        </div>
    );
};

export default TimeBasedDownload;
